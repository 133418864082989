import { API } from "@/common-http.js";
import { defineComponent, ref, onMounted } from "@vue/composition-api";
import { createRoomHelper } from "@/util/roomcreator";
import { useStore } from "@/util/vuex-workaround";
const RoomListView = defineComponent({
    name: "RoomListView",
    setup() {
        let isLoading = ref(false);
        let rooms = ref([]);
        onMounted(async () => {
            isLoading.value = true;
            let result = await API.get("/room/list");
            isLoading.value = false;
            rooms.value = result.data;
        });
        async function createRoom() {
            let store = useStore();
            await createRoomHelper(store);
        }
        return {
            isLoading,
            rooms,
            createRoom,
        };
    },
});
export default RoomListView;
